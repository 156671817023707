/*

  Formatter for form:input

 

const jpostalquery = require('jquery');
require('jquery-jpostal-ja');
jpostalquery(function(){
    jpostalquery('#company_postcode').jpostal({
        postcode: [ '#company_postcode' ],
        address: {
            '#company_prefecture_id': '%3',
            '#company_address': '%4%5',
        }
    });
    jpostalquery('#site_postcode').jpostal({
        postcode: [ '#site_postcode' ],
        address: {
            '#site_prefecture_id': '%3',
            '#site_address': '%4%5',
        }
    });
    jpostalquery('#worker_postcode').jpostal({
        postcode: [ '#worker_postcode' ],
        address: {
            '#worker_prefecture_id': '%3',
            '#worker_address': '%4%5',
        }
    });
    jpostalquery('#worker_emergency_postcode').jpostal({
        postcode: [ '#worker_emergency_postcode' ],
        address: {
            '#worker_emergency_prefecture_id': '%3',
            '#worker_emergency_address': '%4%5',
        }
    });
});
*/